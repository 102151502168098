import Constants from '@/js/constant'
import axios from 'axios'

const HOST = Constants.hosts.url
const HRHOST = Constants.hosts.hrurl
const FAHOST = Constants.hosts.faurl
const ADHOST = Constants.hosts.adurl

const STATUS_LIST = `${HOST}list/status`
const FREQUENCY_LIST = `${HOST}list/frequency`
const DOCUMENT_NAME_LIST = `${HOST}list/document-name`
const SKILLS_LIST = `${HOST}list/skills`
const EMPLOYEE_LIST = `${HRHOST}employee`
const TECHNOLOGIES_LIST = `${ADHOST}technologies`
const TIMESHEET_INVOICE_AMOUNT = `${FAHOST}timesheets/invoice/amount`
const TASK_NAME_LIST = `${HOST}list/task-names`
const EMPLOYEE_CONSULTANT_TIMESHEET_EMPLOYER_LIST = `${HRHOST}employee/timesheet/employer`
const EMPLOYEE_CONSULTANT_TIMESHEET_SUPPLIER_LIST = `${HRHOST}employee/timesheet/supplier`
const EMPLOYEE_CONSULTANT_TIMESHEET_SUBVENDOR_LIST = `${HRHOST}employee/timesheet/subvendor`
const EMPLOYEE_CONSULTANT_TIMESHEET_REFERRER_LIST = `${HRHOST}employee/timesheet/referrer`

export default {
  getTechnologiesListByJobId(context, jobId) {
    axios.get(`${TECHNOLOGIES_LIST}/${jobId}`).then(response => {
      context.setTechnologies(response.data)
    })
  },
  getReferrerTimeSheetEmployeeConsultantFullList(context, id) {
    axios.get(`${EMPLOYEE_CONSULTANT_TIMESHEET_REFERRER_LIST}/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getSubvendorTimeSheetEmployeeConsultantFullList(context, id) {
    axios.get(`${EMPLOYEE_CONSULTANT_TIMESHEET_SUBVENDOR_LIST}/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getSupplierTimeSheetEmployeeConsultantFullList(context, id) {
    axios.get(`${EMPLOYEE_CONSULTANT_TIMESHEET_SUPPLIER_LIST}/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getEmployerTimeSheetEmployeeConsultantFullList(context, id) {
    axios.get(`${EMPLOYEE_CONSULTANT_TIMESHEET_EMPLOYER_LIST}/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getCommunicationTypesFullList(context) {
    axios.get(`${HOST}list/communication-types`).then(response => {
      context.setCommunicationTypes(response.data)
    })
  },
  getInvoiceAmount(context, employeeId, Month, parentTypeId) {
    const params = {
      employee_ids: employeeId, month: Month, parent_type_id: parentTypeId,
    }
    axios.post(TIMESHEET_INVOICE_AMOUNT, params).then(response => {
      context.setVendorAmount(response.data)
    })
  },
  getEmployeeInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/employer/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getSubVendorInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/subvendor/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getSupplierInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/supplier/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getReferrerInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/referrer/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getVendorInvoicesFullList(context, id) {
    axios.get(`${EMPLOYEE_LIST}/invoice/vendor/${id}/fulllist`).then(response => {
      context.setEmployees(response.data)
    })
  },
  getStatusFullList(context, departmentCode, divisionCode) {
    axios.get(`${STATUS_LIST}/${departmentCode}/${divisionCode}/fulllist`).then(response => {
      context.setStatus(response.data)
    })
  },
  getProspectDocumentNameFullList(context) {
    axios.get(`${DOCUMENT_NAME_LIST}/prospect/fulllist`).then(response => {
      context.setDocumentNames(response.data)
    })
  },
  getDocumentNameFullList(context, departmentCode, divisionCode) {
    axios.get(`${DOCUMENT_NAME_LIST}/${departmentCode}/${divisionCode}/fulllist`).then(response => {
      context.setDocumentNames(response.data)
    })
  },
  getTaskNameFullList(context, departmentCode, divisionCode) {
    axios.get(`${TASK_NAME_LIST}/${departmentCode}/${divisionCode}/fulllist`).then(response => {
      context.setTaskNames(response.data)
    })
  },
  getVisaFullList(context) {
    axios.get(`${HOST}list/visa-types`).then(response => {
      context.setVisaTypes(response.data)
    })
  },
  getGenderFullList(context) {
    axios.get(`${HOST}list/genders`).then(response => {
      context.setGenders(response.data)
    })
  },
  getFrequencyFullList(context) {
    axios.get(FREQUENCY_LIST).then(response => {
      context.setFrequencies(response.data)
    })
  },

  getINterviewerDurationFullList(context) {
    axios.get(`${HOST}list/durations`).then(response => {
      context.setDuration(response.data)
    })
  },
  getWeekDaysFullList(context) {
    axios.get(`${HOST}list/days`).then(response => {
      context.setWeekDays(response.data)
    })
  },
  getHolidaysFullList(context) {
    axios.get(`${HOST}list/holidays`).then(response => {
      context.setHolidays(response.data)
    })
  },
  getSkillsFullList(context) {
    axios.get(SKILLS_LIST).then(response => {
      context.setSkills(response.data)
    })
  },

}
