<template>
  <b-card>
    <b-row>
      <b-col
        cols="12"
        md="6"
      >
        <h4 class="card-title mb-0 pt-50">
          Edit Timesheet</h4>
      </b-col>
      <b-col
        cols="12"
        md="6 ml-auto"
      >
        <div class="d-flex align-items-center justify-content-end">
          <b-button
            variant="outline-secondary"
            @click="back"
          >
            Back
          </b-button>
        </div>
      </b-col>
    </b-row>
    <hr>
    <validation-observer ref="simpleRules">
      <b-form>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Employee Name"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="timesheet.name"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Timesheet Frequency"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="timesheet.timesheet_frequency"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Month"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="month"
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="isShowWeeks"
            cols="6"
          >
            <b-form-group
              label="Week"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="week"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-row v-if="isShowWeeks">
          <b-col cols="12">
            <dl class="row">
              <dt class="col-sm-2 text-right mt-50">
                Day's
              </dt>
              <dd class="col-sm-10">
                <div class="table-responsive">
                  <table class="table table-striped table-bordered table-custom-pd mb-0">
                    <thead>
                      <tr>
                        <th>Sun {{ sun_day }}</th>
                        <th>Mon {{ mon_day }}</th>
                        <th>Tue {{ tue_day }}</th>
                        <th>Wed {{ wed_day }}</th>
                        <th>Thu {{ thu_day }}</th>
                        <th>Fri {{ fri_day }}</th>
                        <th>Sat {{ sat_day }}</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.sunday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.monday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.tueday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.wedday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.thusday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.friday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                        <td>
                          <div class="">
                            <validation-provider
                              #default="{ errors }"
                              name="Week"
                              :rules="isShowWeeks ? 'required' : ''"
                            >
                              <b-form-spinbutton
                                v-model="timesheet.satday.hours"
                                :min="0"
                                :max="24"
                                :step="0.5"
                                @input="hoursChanged()"
                              />
                              <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                          </div>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </dd>
            </dl>
          </b-col>
        </b-row>
        <b-row>
          <b-col cols="6">
            <b-form-group
              label="Total Hours"
              label-cols-md="4"
              label-align="right"
            >
              <validation-provider
                #default="{ errors }"
                name="Total Hours"
                rules="required"
              >
                <b-form-input
                  v-model="timesheet.total_hours"
                  :disabled="!isShowWeeks ? disabled: ''"
                  type="number"
                  :state="errors.length > 0 ? false:null"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col
            v-show="User.profile().allow_timesheet_parents[0].is_expenses_exist"
            cols="6"
          >
            <b-form-group
              label="Expenses"
              label-cols-md="4"
              class="text-right"
            >
              <validation-provider
                #default="{ errors }"
                name="Expenses"
                rules="required"
              >
                <b-input-group
                  prepend="$"
                >
                  <money
                    v-model="timesheet.expenses"
                    :min="0"
                    :step="0.5"
                    v-bind="money"
                    class="form-control"
                  />
                </b-input-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Status"
              label-cols-md="4"
              label-align="right"
            >
              <span
                class="form-control"
                v-text="timesheet.status"
              />
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-form-group
              label="Reject Reason"
              label-cols-md="4"
              label-align="right"
            >
              <b-form-textarea
                v-model="timesheet.reject_reason"
                disabled
                rows="2"
              />
            </b-form-group>
          </b-col>
        </b-row>
        <hr>

        <div class="d-flex mt-2">
          <h4 class="mb-0 ml-50">
            Documents
          </h4>
        </div>
        <b-row class="mt-1">
          <b-col cols="6">
            <b-form-group
              label="Type"
              label-cols-md="4"
              class="text-right"
            >
              <v-select
                v-model="documentNameId"
                :clearable="true"
                placeholder="Select"
                :options="documentNames"
                :reduce="item => item.id"
              />
            </b-form-group>
            <b-form-group
              label="Attachment"
              label-cols-md="4"
              class="text-right"
            >
              <div class="d-flex">
                <input
                  ref="myFileInput"
                  type="file"
                  placeholder="Choose a file or drop it here..."
                  class="form-control text-left"
                  @change="attahamentFileSelected"
                >
                <b-button
                  variant="outline-primary"
                  class="ml-1 px-1"
                  @click="addFile"
                >
                  Upload
                </b-button>
              </div>
            </b-form-group>
          </b-col>
          <b-col cols="6">
            <b-button-group
              v-for="(option, index) in timesheet.documents"
              :key="option.id"
              :value="option.id"
              class="mr-1"
            >
              <b-badge
                :href="option.attachment_link"
                :title="option.name"
                target="option.attachment_link"
                variant="light-primary"
                class="rounded-right-0 line-height-inherit"
              >
                <span>{{ option.name }}</span>
              </b-badge>
              <b-button
                variant="danger"
                class="px-50 py-25"
                @click="removeFile(option, index)"
              >
                <feather-icon icon="XIcon" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>
        <hr>

        <b-row class="ml-2">
          <b-col class="text-right">
            <b-button
              variant="primary"
              type="button"
              @click="submitForm"
            >
              Update Timesheet
            </b-button>
          </b-col>
        </b-row>

      </b-form>
    </validation-observer>
  </b-card>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import vSelect from 'vue-select'
import {
  BCard, BRow, BCol, BForm, BFormGroup, BInputGroup, BFormInput, BButtonGroup, BButton, BBadge, BFormSpinbutton, BFormTextarea,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import {
  required,
} from '@validations'
import User from '@/js/user'
import Timesheet from '@/js/timesheets'
import LIST from '@/js/lists'
import moment from 'moment'
import Constants from '@/js/constant'

export default {
  components: {
    BCard,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BForm,
    BFormGroup,
    BInputGroup,
    BFormInput,
    vSelect,
    BButton,
    BButtonGroup,
    BBadge,
    BFormSpinbutton,
    BFormTextarea,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      required,
      User,
      timesheet: {
        expenses: '',
        documents: [],
      },
      money: {
        decimal: '.',
        thousands: ',',
        prefix: '',
        suffix: '',
        precision: 2,
        masked: false,
      },
      url: null,
      sun_day: null,
      mon_day: null,
      tue_day: null,
      wed_day: null,
      thu_day: null,
      fri_day: null,
      sat_day: null,
      week: '',
      month: '',
      documentNameId: null,
      isShowWeeks: true,
      selectedFile: null,
      isShowDays: true,
      documentNames: [],
    }
  },
  mounted() {
  },
  created() {
    if (!User.profile().timesheet_provider_parents.length > 0) {
      if (User.profile().job_exist) {
        window.location.hash = '#/jobs'
      } else if (User.profile().invoice_exist) {
        window.location.hash = '#/invoices'
      } else {
        // Auth.logout()
      }
      return
    }
    LIST.getDocumentNameFullList(this, 'FA', 'TS')
    Timesheet.getTimeSheetById(this, this.$route.params.id)
  },
  destroyed() {
  },
  methods: {
    submitForm() {
      this.$refs.simpleRules.validate().then(result => {
        if (result) {
          if (this.documentNameId !== null && this.selectedFile === null) {
            this.$swal({
              title: 'Upload',
              text: 'Please choose file',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId === null && this.selectedFile !== null) {
            this.$swal({
              title: 'Upload',
              text: 'Please select document type',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-primary',
              },
              buttonsStyling: false,
            })
            return
          }
          if (this.documentNameId !== null && this.selectedFile !== null) {
            this.timesheet.documents.push({
              document_name_id: this.documentNameId,
              attachment_link: this.url,
              document: this.selectedFile,
              file_name: this.documentName,
              name: this.documentName,
            })
            this.selectedFile = null
            this.url = null
            this.documentNameId = null
            this.$refs.myFileInput.value = ''
          }
          let documentExist = false
          this.timesheet.documents.forEach(elem => {
            if (elem.document_name_id === 38) documentExist = true
          })
          if (documentExist) {
            this.submitted = true
            const monthDate = this.month.split('-')
            this.timesheet.monthDate = Constants.getEprmMonthDateFromString(`${moment(monthDate[0], 'MMMM').format('MM')}-01-${monthDate[1]}`)
            Timesheet.updateTimesheet(this, this.$route.params.id)
          } else {
            this.$swal({
              title: 'Please upload approved timesheet',
            })
          }
        }
      })
    },
    attahamentFileSelected(obj) {
      const dis = this
      const fileReader = new FileReader()
      fileReader.onload = fileLoadEvent => {
        dis.selectedFile = fileLoadEvent.target.result
      }
      fileReader.readAsDataURL(obj.target.files[0])
      this.url = URL.createObjectURL(obj.target.files[0])
      this.documentName = obj.target.files[0].name
    },
    removeFile(obj, position) {
      const dis = this
      this.$swal({
        title: 'Are you sure?',
        text: `you wan't to delete this ${obj.name}`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: 'btn btn-outline-primary ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          dis.timesheet.documents.splice(position, 1)
        }
      })
    },
    addFile() {
      if (this.documentNameId !== null && this.selectedFile === null) {
        this.$swal({
          title: 'Error!',
          text: ' Please choose file',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId === null && this.selectedFile !== null) {
        this.$swal({
          title: 'Error!',
          text: ' Please select document type',
          icon: 'error',
          customClass: {
            confirmButton: 'btn btn-primary',
          },
          buttonsStyling: false,
        })
        return
      }
      if (this.documentNameId !== null && this.selectedFile !== null) {
        this.timesheet.documents.push({
          document_name_id: this.documentNameId, attachment_link: this.url, document: this.selectedFile, file_name: this.documentName, name: this.documentName,
        })
        this.selectedFile = null
        this.url = null
        this.documentNameId = null
        this.$refs.myFileInput.value = ''
      }
    },
    hoursChanged() {
      let totlaHours = 0
      if (this.timesheet.sunday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.sunday.hours)
      }
      if (this.timesheet.monday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.monday.hours)
      }
      if (this.timesheet.tueday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.tueday.hours)
      }
      if (this.timesheet.wedday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.wedday.hours)
      }
      if (this.timesheet.thusday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.thusday.hours)
      }
      if (this.timesheet.friday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.friday.hours)
      }
      if (this.timesheet.satday.hours != null) {
        totlaHours = Number(totlaHours) + Number(this.timesheet.satday.hours)
      }
      this.timesheet.total_hours = totlaHours
    },
    setDocumentNames(documentNames) {
      this.documentNames = documentNames
    },
    setTimeSheet(timesheet) {
      this.timesheet = timesheet
      this.month = moment(timesheet.month_date, 'MM-DD-YYYY').format('MMM-YYYY')
      if (timesheet.timesheet_frequency === 'weekly') {
        this.isShowWeeks = true
        this.isShowDays = true
        this.week = `${timesheet.week_start_date} - ${timesheet.week_end_date}`
        this.sun_day = moment(Constants.getDateStringFromEprmDate(timesheet.sunday.date), 'MM-DD-YYYY').format('MM/DD')
        this.mon_day = moment(Constants.getDateStringFromEprmDate(timesheet.monday.date), 'MM-DD-YYYY').format('MM/DD')
        this.tue_day = moment(Constants.getDateStringFromEprmDate(timesheet.tueday.date), 'MM-DD-YYYY').format('MM/DD')
        this.wed_day = moment(Constants.getDateStringFromEprmDate(timesheet.wedday.date), 'MM-DD-YYYY').format('MM/DD')
        this.thu_day = moment(Constants.getDateStringFromEprmDate(timesheet.thusday.date), 'MM-DD-YYYY').format('MM/DD')
        this.fri_day = moment(Constants.getDateStringFromEprmDate(timesheet.friday.date), 'MM-DD-YYYY').format('MM/DD')
        this.sat_day = moment(Constants.getDateStringFromEprmDate(timesheet.satday.date), 'MM-DD-YYYY').format('MM/DD')
      } else {
        this.isShowWeeks = false
        this.isShowDays = false
      }
    },
    timesheetUpdated(data) {
      this.$refs.showLoading = false
      this.back()
      this.$swal({
        title: 'Success!',
        text: data.return_message,
        icon: 'success',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    timesheetNotUpdated(message) {
      this.$refs.showLoading = false
      this.$swal({
        title: 'Error!',
        text: message,
        icon: 'error',
        customClass: {
          confirmButton: 'btn btn-primary',
        },
        buttonsStyling: false,
      })
    },
    back() {
      this.$router.push('/timesheets').catch(() => {})
    },
  },
  setup() {
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>
