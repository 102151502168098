import axios from 'axios'
import moment from 'moment'
import Constants from '@/js/constant'

const HOST = Constants.hosts.faurl
const timesheet = `${HOST}timesheets`

export default {
  getTimesheets(context1) {
    const context = context1
    const queryParams = { query: context.$store.state.query.query, page: context.$store.state.query.page - 1, type: context.type }
    let params = {}
    if (context.$store.state.query.activeTab === 'Weekly') {
      if (context.$store.state.query.startdate !== null && context.$store.state.query.startdate !== '') {
        queryParams.start_date = moment(context.$store.state.query.startdate, 'MM-DD-YYYY').format('DD')
        queryParams.start_month = moment(context.$store.state.query.startdate, 'MM-DD-YYYY').format('MM')
        queryParams.start_year = moment(context.$store.state.query.startdate, 'MM-DD-YYYY').format('YYYY')
      }
      if (context.$store.state.query.endDate !== null && context.$store.state.query.endDate !== '') {
        queryParams.end_date = moment(context.$store.state.query.endDate, 'MM-DD-YYYY').format('DD')
        queryParams.end_month = moment(context.$store.state.query.endDate, 'MM-DD-YYYY').format('MM')
        queryParams.end_year = moment(context.$store.state.query.endDate, 'MM-DD-YYYY').format('YYYY')
      }
    }
    if (queryParams !== {}) {
      params = { params: queryParams }
    } else {
      params = { params: {} }
    }
    axios.get(`${timesheet}/external`, params).then(response => {
      context.setTimesheets(response.data)
    }, () => {
    })
  },
  getRequestTimesheets(context1, id, date1, month1, year1) {
    const context = context1
    const params = { params: { date: date1, month: month1, year: year1 } }
    axios.get(`${timesheet}/external/${id}`, params).then(response => {
      context.setTimeSheets(response.data)
    }, () => {
    })
  },
  addTimesheet(context1, body, isAccepted) {
    const context = context1
    context.$vs.loading()
    const params = { params: { is_accepted: isAccepted } }
    axios.post(`${timesheet}/external`, body, params).then(response => {
      context.$vs.loading.close()
      context.timesheetAdded(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotAdded(error.response.data)
    })
  },
  updateTimesheet(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.put(`${timesheet}/external/${id}`, context.timesheet).then(response => {
      context.$vs.loading.close()
      context.timesheetUpdated(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotUpdated(error.response.data.return_message)
    })
  },
  delete(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.delete(`${timesheet}/${id}`).then(response => {
      context.$vs.loading.close()
      context.timesheetDeleted(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotDeleted(error.response.data.return_message)
    })
  },
  getTimeSheetById(context1, id) {
    const context = context1
    context.$vs.loading()
    axios.get(`${timesheet}/${id}`).then(response => {
      context.$vs.loading.close()
      context.setTimeSheet(response.data)
    }, error => {
      context.$vs.loading.close()
      context.timesheetNotFound(error.response.data.return_message)
    })
  },
}
